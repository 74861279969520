import MoveTo from "moveto";

export default function smoothscroll() {
  moveTo.registerTrigger(toTopBtn);
  if (toPageId) {
    toPageId.forEach(value => {
      moveTo.registerTrigger(value);
    });
  }
}

const toTopBtn = document.getElementById(`to_top`);
const toPageId = Array.from(document.querySelectorAll(`a[href^="#"]`));

const moveTo = new MoveTo({
  tolerance: 0,
  duration: 800,
  easing: "easeOutQuart",
  container: window
});
