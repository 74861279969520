export default function btn() {
  const gnav = document.getElementById(`gnav`),
    ham = document.getElementById(`hamburger`),
    hamtxt = document.getElementById(`hamburger-text`),
    haminnertxt = hamtxt.innerHTML,
    hamaftertxt = `閉じる`;
  const navopen = [gnav, ham];
  ham.addEventListener("click", e => {
    const isOpen = ham.classList.contains(`is-open`);
    if (!isOpen) {
      navopen.forEach(i => {
        i.classList.add(`is-open`);
      });
      hamtxt.innerHTML = hamaftertxt;
    }
    if (isOpen) {
      navopen.forEach(i => {
        i.classList.remove(`is-open`);
      });
      hamtxt.innerHTML = haminnertxt;
    }
  });
}
