/* Pluguins
==================== */
import "@babel/polyfill"; // IE11でES6を動かすのに必要
import "svgxuse"; // IE11でsvgスプライトを使うのに必要
import "picturefill"; // IE11でpictureを使うのに必要
// import "intersection-observer"; // IE11でpintersection-observerを使うのに必要
// import Vue from "vue";
// import "lazysizes"; // 画像の遅延読み込みに使用
// import Bound from 'bounds.js' //スクロールイベントの奴
// import $ from "jquery"; // jQuery:$はimportしなくてもどこでも使えます
// import _ from "lodash"; // lodashは使用する関数を直接指定してimportしてください
// lodashの読み込み参考: https://s8a.jp/javascript-lodash-reduce-file-size#lodash%E3%81%AE%E5%88%A9%E7%94%A8%E3%81%99%E3%82%8B%E9%96%A2%E6%95%B0%E3%82%92%E7%9B%B4%E6%8E%A5%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B%E7%9B%B4%E6%8E%A5%E6%8C%87%E5%AE%9A

/* Modules
==================== */
import btn from "./modules/hamburger";
btn();
import smoothscroll from "./modules/smoothscroll";
smoothscroll();
/* Script
==================== */
// コンソールログはプロダクションモードでは取り除かれるので、自由に使ってもらって構いません。
